import React, { useState } from "react";
import profile from "../images/avatar.jpeg";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  ListItem,
  IconButton,
  ListItemText,
  ListItemIcon,
  Avatar,
  Divider,
  List,
  Typography,
  Box,
  Drawer,
} from "@material-ui/core";
import {
  Menu,
  AssignmentInd,
  Home,
  Apps,
  ContactMail,
} from "@material-ui/icons";
import { Link } from "react-router-dom";

import NavFooter from "./navFooter";

//CSS Styling
const useStyles = makeStyles((theme) => ({
  sideBarContainer: {
    width: 250,
    background: "#6f2232",
    height: "100%",
  },
  avatar: {
    display: "block",
    margin: "0.5rem auto",
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const menuItems = [
  {
    listIcon: <Home />,
    listText: "Home",
    listPath: "/",
  },
  {
    listIcon: <Apps />,
    listText: "Portfolio",
    listPath: "/portfolio",
  },
  {
    listIcon: <ContactMail />,
    listText: "Contact",
    listPath: "/contact",
  },
  {
    listIcon: <AssignmentInd />,
    listText: "Resume",
    listPath: "/resume",
  },
];

const NavBar = (props) => {
  const [state, setState] = useState({
    left: false,
  });

  const toggleSideBar = (slider, open) => () => {
    setState({ ...state, [slider]: open });
  };

  const classes = useStyles();

  const sideBar = (slider) => (
    <Box
      component="div"
      className={classes.sideBarContainer}
      onClick={toggleSideBar(slider, false)}
    >
      <Avatar
        className={classes.avatar}
        src={profile}
        alt="Headshot of Harrish"
      />
      <Divider />
      <List>
        {menuItems.map((lsItem) => (
          <ListItem
            button
            key={lsItem.listText}
            component={Link}
            to={lsItem.listPath}
          >
            <ListItemIcon>{lsItem.listIcon}</ListItemIcon>
            <ListItemText primary={lsItem.listText} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Box component="nav">
        <AppBar position="static" style={{ backgroundColor: "#6f2232" }}>
          <Toolbar>
            <IconButton onClick={toggleSideBar("left", true)}>
              <Menu />
            </IconButton>
            <Typography variant="h5" style={{ color: "#371219" }}>
              HS
            </Typography>
          </Toolbar>
          <Drawer
            open={state.left}
            anchor="left"
            onClose={toggleSideBar("left", false)}
          >
            {sideBar("left")}
            <NavFooter />
          </Drawer>
        </AppBar>
      </Box>
    </div>
  );
};

export default NavBar;
