import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Avatar, Grid, Box } from "@material-ui/core";
import Typed from "react-typed";

import avatar from "../images/avatar.jpeg";

//CSS Styling
const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    margin: theme.spacing(1),
    border: "1px solid rgba(78, 78, 80, 0.3)",
  },
  title: {
    color: "#6f2232",
    textStroke: "1px #371219",
    fontWeight: "900",
  },
  subtitle: {
    color: "#fffafa",
    textStroke: "0.5px #1a1a1d",
    marginBottom: "3rem",
  },
  textContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    textAlign: "center",
    zIndex: 1,
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <Box className={classes.textContainer}>
      <Grid container justify="center">
        <Avatar src={avatar} alt="Harrish Pic" className={classes.avatar} />
      </Grid>
      <Typography variant="h4" className={classes.title}>
        <Typed strings={["Harrish Suhumar"]} typeSpeed={40} />
      </Typography>
      <br />
      <Typography variant="h5" className={classes.subtitle}>
        <Typed
          strings={[
            "Full Stack Developer",
            "Freelancer",
            "Tech and Fitness Enthusiast",
          ]}
          typeSpeed={40}
          backSpeed={60}
          loop
        />
      </Typography>
    </Box>
  );
};

export default Header;
