import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TextField, Typography, Button, Grid, Box } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import Particles from "react-particles-js";
import emailjs from "emailjs-com";

import NavBar from "./navBar";

//CSS styling
const useStyles = makeStyles({
  particlesCanvas: {
    position: "absolute",
    opacity: "0.3",
  },
  form: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#371219",
    },
    "& label": { color: "#371219" },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "2px solid #371219",
    },
  },
})(TextField);

const Contact = () => {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  const handleChange = (event) => {
    if (event.target.name === "email") {
      setEmail(event.target.value);
    } else if (event.target.name === "msg") {
      setMsg(event.target.value);
    } else if (event.target.name === "name") {
      setName(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    emailjs
      .send(
        "gmail",
        "template_5nDKUziy_clone",
        { from_name: name, from_email: email, message_html: msg },
        "user_ylO826sTBGKDQTCOttjK7"
      )
      .then((res) => {
        window.location = "/";
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box component="div">
      <NavBar />
      <Particles
        canvasClassName={classes.particlesCanvas}
        params={{
          particles: {
            number: {
              value: 60,
              density: {
                enable: true,
                value_area: 900,
              },
            },
            shape: {
              type: "circle",
              stroke: {
                width: 1,
                color: "#6f2232",
              },
            },
            size: {
              value: 8,
              random: true,
              anim: {
                enable: true,
                speed: 5,
                size_min: 0.1,
                sync: true,
              },
            },
            opacity: {
              value: 1,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.1,
                sync: true,
              },
            },
          },
        }}
      />
      <Grid container justify="center">
        <Box component="form" className={classes.form} onSubmit={handleSubmit}>
          <Typography
            variant="h5"
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              color: "#371219",
              fontWeight: 900,
            }}
          >
            Hire or Contact Me
          </Typography>
          <InputField
            fullWidth={true}
            label="Name"
            variant="filled"
            margin="dense"
            size="medium"
            inputProps={{ style: { color: "#fffafa" } }}
            name="name"
            onChange={handleChange}
          />
          <br />
          <InputField
            fullWidth={true}
            label="Email"
            variant="filled"
            margin="dense"
            size="medium"
            inputProps={{ style: { color: "#fffafa" } }}
            name="email"
            onChange={handleChange}
          />
          <br />
          <InputField
            fullWidth={true}
            label="Message"
            variant="filled"
            margin="dense"
            size="medium"
            inputProps={{ style: { color: "#fffafa" } }}
            name="msg"
            onChange={handleChange}
          />
          <Button
            variant="outlined"
            fullWidth="true"
            endIcon={<SendIcon />}
            style={{
              marginTop: "10px",
              color: "#371219",
              border: "1px solid #371219",
            }}
            type="submit"
          >
            Send
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

export default Contact;
