import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./App.css";
import { Route } from "react-router-dom";

import Index from "./components/index";
import Portfolio from "./components/portfolio";
import Contact from "./components/contact";

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <Route exact path="/" component={Index} />
      <Route
        path="/resume"
        component={() => {
          window
            .open(
              `https://drive.google.com/file/d/1g-F-RYibTHJnqayRs2cp7cgdoix9JR3F/view?usp=sharingS`,
              "_blank"
            )
            .then((window.location = "/"));
        }}
      />
      <Route path="/portfolio" component={Portfolio} />
      <Route path="/contact" component={Contact} />
    </div>
  );
}

export default App;
