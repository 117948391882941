import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@material-ui/core";
import Particles from "react-particles-js";

import NavBar from "./navBar";
import waitless from "../images/Waitless.jpg";
import munchUp from "../images/munchUp.jpg";
import burger from "../images/burgerBuilder.jpg";
import snapFood from "../images/snapFood.jpg";
import rgPrint from "../images/rgPrint.png";

const useStyles = makeStyles({
  mainContainer: {
    height: "100%",
  },
  cardContainer: {
    maxWidth: 345,
    margin: "5rem auto",
  },
  particlesCanvas: {
    position: "absolute",
    opacity: "0.3",
  },
});

const Portfolio = () => {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.mainContainer}>
      <NavBar />
      <Particles
        canvasClassName={classes.particlesCanvas}
        params={{
          particles: {
            number: {
              value: 60,
              density: {
                enable: true,
                value_area: 900,
              },
            },
            shape: {
              type: "circle",
              stroke: {
                width: 1,
                color: "#6f2232",
              },
            },
            size: {
              value: 8,
              random: true,
              anim: {
                enable: true,
                speed: 5,
                size_min: 0.1,
                sync: true,
              },
            },
            opacity: {
              value: 1,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.1,
                sync: true,
              },
            },
          },
        }}
      />
      <Grid container justify="center">
        {/* Rg Print */}
        <Grid item xs={12} sm={8} md={6}>
          <Card className={classes.cardContainer} style={{ height: 400 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Rg Print"
                height="200"
                image={rgPrint}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Rg Printing Website
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Built website for local printing company using React.
                  Implemented smooth scroll, react helmet and functional contact
                  form for enquiries. Also tasked with hosting, and domain
                  management.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <a
                href="https://rgprinting.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button size="small" color="primary">
                  Live Website
                </Button>
              </a>
            </CardActions>
          </Card>
        </Grid>
        {/* SnapFood */}
        <Grid item xs={12} sm={8} md={6}>
          <Card className={classes.cardContainer} style={{ height: 400 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Snapfood"
                height="200"
                image={snapFood}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  SnapFood
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Team Project (Time: 2 weeks)
                  <br /> Tech Stack: NodeJS, Express, MongoDB, TypeScript, React
                  Native
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <a
                href="https://github.com/harrishs/snapFood-frontend"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button size="small" color="primary">
                  Github (Front End)
                </Button>
              </a>
              <a
                href="https://github.com/harrishs/fastFood"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button size="small" color="primary">
                  Github (Back End)
                </Button>
              </a>
            </CardActions>
          </Card>
        </Grid>
        {/* Waitless */}
        <Grid item xs={12} sm={8} md={6}>
          <Card className={classes.cardContainer} style={{ height: 400 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Waitless"
                height="200"
                image={waitless}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Waitless
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Team Project (Time: 2 weeks)
                  <br />
                  Tech Stack: NodeJS, Express, PSQL, EJS, CSS
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <a
                href="https://github.com/harrishs/waitless"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button size="small" color="primary">
                  Github
                </Button>
              </a>
              <a
                href="https://waitless-lhl.herokuapp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button size="small" color="primary">
                  Live Demo
                </Button>
              </a>
            </CardActions>
          </Card>
        </Grid>
        {/* Munch Up */}
        <Grid item xs={12} sm={8} md={6}>
          <Card className={classes.cardContainer} style={{ height: 400 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Munchup"
                height="200"
                image={munchUp}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Munch Up
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Team Project (Time: 1 week)
                  <br />
                  Tech Stack: NodeJS, Express, PSQL, EJS, CSS
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <a
                href="https://github.com/harrishs/Munch-Up-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button size="small" color="primary">
                  Github
                </Button>
              </a>
            </CardActions>
          </Card>
        </Grid>
        {/* Burger Builder */}
        <Grid item xs={12} sm={8} md={6}>
          <Card className={classes.cardContainer} style={{ height: 400 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="burger builder"
                height="200"
                image={burger}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Burger Builder
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Course Based Project <br />
                  Tech Stack: React, Firebase, Redux
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <a
                href="https://github.com/harrishs/burger-builder"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button size="small" color="primary">
                  Github
                </Button>
              </a>
              <a
                href="https://react-burger-93ee8.firebaseapp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button size="small" color="primary">
                  Live Demo
                </Button>
              </a>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Portfolio;
