import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import EmailIcon from "@material-ui/icons/Email";

//CSS Styles
const useStyles = makeStyles({
  root: {
    "& .MuiBottomNavigationAction-root": {
      minWidth: 0,
      maxWidth: 250,
    },
  },
});

const NavFooter = () => {
  const classes = useStyles();
  return (
    <BottomNavigation width="auto" style={{ background: "#1a1a1d" }}>
      <BottomNavigationAction
        style={{ padding: 0, color: "#fafafa" }}
        icon={<LinkedInIcon />}
        className={classes.root}
        onClick={() => {
          window.open(`https://www.linkedin.com/in/harrish-suhumar/`, "_blank");
        }}
      />
      <BottomNavigationAction
        style={{ padding: 0, color: "#fafafa" }}
        icon={<EmailIcon />}
        className={classes.root}
        onClick={() => {
          window.open("mailto:harrish.s@hotmail.ca", "_self");
        }}
      />
      <BottomNavigationAction
        style={{ padding: 0, color: "#fafafa" }}
        icon={<GitHubIcon />}
        className={classes.root}
        onClick={() => {
          window.open(`https://github.com/harrishs`, "_blank");
        }}
      />
    </BottomNavigation>
  );
};

export default NavFooter;
